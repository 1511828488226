import React from "react";
// import CalendarImage from '../../../images/pediatric-landing-page/wakix-timeline-icon.webp';
import "./card-timeline.scss";

function CardTimeline({
    ClassName,
    cardTitle,
    cardSubTitle,
    // cardDate,
    imgSrc,
    imgAlt,
    ...props
}) {
    return (
        <article {...props} className={`card-timeline ${ClassName ? ClassName : ""}`}>
            <div className="card-timeline__media">
                <img src={imgSrc} alt={imgAlt} />
                {/* <h3 className="card-date">{cardDate}</h3> */}
            </div>

            {cardTitle ? (
                <div className="card-timeline__title">
                    <h4>
                        {cardTitle}
                        <span dangerouslySetInnerHTML={{ __html: cardSubTitle }} ></span>
                    </h4>
                </div>
            ) : ""}
        </article>
    );
}


export default CardTimeline;