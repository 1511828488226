import React from "react";
import HcpLayout from "../components/hcp/layout";
import { Seo } from "../components/seo";
import { TfiAngleRight } from "react-icons/tfi";
import useDeviceSize from "../hooks/use-device-size";
import { StaticImage } from "gatsby-plugin-image";
import LazyLoad from "react-lazy-load";
import { Col, Row } from "react-grid-system";
import SocialIconBlock from "../components/peds-landingPage/socialIconBlock";
import copayIcon from "../images/icons/icons-copay.svg";
import bannerImg from "../images/pediatric-landing-page/hero-banner.webp";
import bannerImgMobile from "../images/pediatric-landing-page/hero-banner-mobile.webp";
import CardTimeline from "../components/peds-landingPage/cardTimeline";
import CardDocument from "../components/peds-landingPage/cardDocument";
import caregiverPamphlet from "../images/pediatric-landing-page/caregiver-pamphelt-thumbnail.webp";
import pediatricFlashcard from "../images/pediatric-landing-page/pediatric-flashcard-thumbnail.webp";
import signUpIcon from "../images/pediatric-landing-page/sign-up-icon.webp";
// import tipsThumbnail from '../images/pediatric-landing-page/tips-thumbnail.webp'
import wakeUpTo from "../images/pediatric-landing-page/WUTW-Italic-glow.webp";
import wakeUpToMobile from "../images/pediatric-landing-page/WUTW-Italic-mobile.webp";
import timelineAugIconImg from "../images/pediatric-landing-page/timeline-aug-icon.webp";
import timelineOctIconImg from "../images/pediatric-landing-page/timeline-oct-icon.webp";
import timelineJuneIconImg from "../images/pediatric-landing-page/timeline-june-icon.webp";
import "./now-approved-in-pediatric-patients.scss";

const PediatricLandingPage = () => {
    const deviceSizes = useDeviceSize();

    return (
        <HcpLayout
            pageClass="hcp-main hcp-pediatric-landing-page"
            copyRightYear="2024"
            jobCode="US-WAK-2400277/Jul 2024"
            noStickyHCPLocatorCTA={true}
        >
            <div className="hero-wrapper row-full">
                <div className="hero-content-wrapper">
                    <div className="hero-banner inner bg-cover">
                        {deviceSizes?.smUp ? (
                            <LazyLoad offset={300} debounce={false}>
                                <img
                                    src={bannerImg}
                                    alt="Parent or caregiver with child doing homework at table"
                                />
                            </LazyLoad>
                        ) : (
                            <LazyLoad offset={300} debounce={false}>
                                <img
                                    src={bannerImgMobile}
                                    alt="Parent or caregiver with child doing homework at table"
                                />
                            </LazyLoad>
                        )}
                        <div className="hero-banner__content">
                            <div className="centered-content">
                                <div className="image-wrapper">
                                    <StaticImage
                                        className="home-hero-logo"
                                        src="../images/pediatric-landing-page/home-hero-logo.webp"
                                        // alt="Child living with Narcolepsy"
                                        placeholder="none"
                                        style={{ transition: "opacity 0.5s" }}
                                        imgStyle={{
                                            width: "100%",
                                            height: "auto",
                                            opacity: "1",
                                        }}
                                        quality={95}
                                        layout="constrained"
                                    />
                                </div>

                                <div className="wake-up-wrapper">
                                    {deviceSizes?.mdDown ? (
                                        <img
                                            src={wakeUpToMobile}
                                            className="hero-wakeup-text"
                                            alt=""
                                            width="375"
                                            height="240"
                                        />
                                    ) : (
                                        <img
                                            src={wakeUpTo}
                                            className="hero-wakeup-text"
                                            alt=""
                                            width="371"
                                            height="288"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="hero-bottom-info bg-yellow">
                        <div className="centered-content">
                            <div className="block-content">
                                <h3 className="font-25 asap">
                                    WAKIX is NOW FDA APPROVED to treat excessive
                                    daytime sleepiness (EDS) in children 6 years
                                    of age and older with narcolepsy.{" "}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="block-why-wakix">
                <div className="centered-content">
                    <div className="block-title">
                        <h1 className="green font-40">Why WAKIX?</h1>
                    </div>
                    <div className="section-card_items">
                        <ul className="icon-list">
                            <li>
                                <div className="icon">
                                    <StaticImage
                                        src="../images/pediatric-landing-page/why-wakix-icons-once-daily.webp"
                                        alt="Once daily calendar icon"
                                        quality={95}
                                        layout="constrained"
                                        placeholder="none"
                                        style={{ transition: "opacity 0.5s" }}
                                        imgStyle={{ opacity: 1 }}
                                    />
                                </div>
                                <div className="text">
                                    <h3>
                                        Once-daily tablet taken in the morning
                                    </h3>
                                    <span>
                                        WAKIX is a{" "}
                                        <strong>
                                            once-daily tablet medication taken
                                            in the morning
                                        </strong>{" "}
                                        as soon as your child wakes up.
                                    </span>
                                </div>
                            </li>

                            <li>
                                <div className="icon">
                                    <StaticImage
                                        src="../images/pediatric-landing-page/why-wakix-icons-not-controlled.webp"
                                        alt="Not a controlled substance pill bottle icon"
                                        placeholder="none"
                                        quality={95}
                                        layout="constrained"
                                        style={{ transition: "opacity 0.5s" }}
                                        imgStyle={{ opacity: 1 }}
                                    />
                                </div>

                                <div className="text">
                                    <h3>
                                        Not a{" "}
                                        <span className="tooltip green">
                                            controlled substance
                                            <span className="tooltip-text">
                                                A drug or chemical that is
                                                regulated by the government
                                                based on its potential for abuse
                                                and dependence.
                                            </span>
                                        </span>
                                    </h3>
                                    <span>
                                        WAKIX is the first and only{" "}
                                        <nobr>FDA-approved</nobr> treatment for
                                        people with narcolepsy that is{" "}
                                        <strong className="italic">not</strong>
                                        <strong> a controlled substance</strong>
                                        .
                                    </span>
                                </div>
                            </li>

                            <li>
                                <div className="icon">
                                    <StaticImage
                                        src="../images/pediatric-landing-page/why-wakix-icons-not-a-stimulant.webp"
                                        alt="Not a stimulant icon"
                                        placeholder="none"
                                        quality={100}
                                        layout="constrained"
                                        style={{ transition: "opacity 0.5s" }}
                                        imgStyle={{ opacity: 1 }}
                                        // className="min-w-84"
                                    />
                                </div>
                                <div className="text">
                                    <h3>Not a stimulant</h3>
                                    <span>
                                        WAKIX is{" "}
                                        <strong className="italic">not</strong>
                                        <strong> a stimulant</strong>, so the
                                        way your child’s body feels when taking
                                        WAKIX may be different from medications
                                        they have taken in the past.
                                    </span>
                                </div>
                            </li>

                            <li>
                                <div className="icon">
                                    <StaticImage
                                        src="../images/pediatric-landing-page/why-wakix-icons-first-class.webp"
                                        alt="First-of-its-kind icon"
                                        placeholder="none"
                                        quality={95}
                                        layout="constrained"
                                        style={{ transition: "opacity 0.5s" }}
                                        imgStyle={{ opacity: 1 }}
                                    />
                                </div>
                                <div className="text">
                                    <h3>First-of-its-kind</h3>
                                    <span>
                                        WAKIX is a{" "}
                                        <strong>
                                            first-of-its-kind medication
                                        </strong>{" "}
                                        that increases{" "}
                                        <strong>histamine</strong> levels in the
                                        brain.
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="block-more-about-wakix">
                <div className="centered-content">
                    <div className="block-title">
                        <h1 className="green font-40">More About WAKIX</h1>
                        <h2>FDA approval timeline:</h2>
                    </div>

                    <Row>
                        <Col md={6} lg={4}>
                            <CardTimeline
                                imgSrc={timelineAugIconImg}
                                imgAlt="August 2019 calendar icon"
                                cardTitle="FDA approved to treat"
                                cardSubTitle="excessive daytime sleepiness <br> (EDS) in adults with narcolepsy"
                            />
                        </Col>

                        <Col md={6} lg={4}>
                            <CardTimeline
                                imgSrc={timelineOctIconImg}
                                imgAlt="October 2020 calendar icon"
                                cardTitle="FDA approved to treat"
                                cardSubTitle="cataplexy in adults <br> with narcolepsy"
                            />
                        </Col>

                        <Col md={6} lg={4}>
                            <CardTimeline
                                imgSrc={timelineJuneIconImg}
                                imgAlt="June 2024 calendar icon"
                                cardTitle="NOW FDA APPROVED to treat"
                                cardSubTitle="EDS or cataplexy in people 6 years of age and older with narcolepsy"
                            />
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="block-wakix-documents">
                <div className="centered-content">
                    <Row gutterWidth={0} justify="center">
                        <Col md={6} lg={4} className="col">
                            <CardDocument
                                ClassName="bg-gray"
                                imgAlt="WAKIX pamphlet for caregivers of children living with narcolepsy thumbnail"
                                imgRef={caregiverPamphlet}
                                imgClass="hasBoxShadow"
                                cardDesc="Learn about the WAKIX clinical study, how to take WAKIX, what to expect with WAKIX, and other important information."
                                cardUrl="/pdf/Pamphlet-for-Caregivers-of-Children-with-Narcolepsy.pdf"
                                btnText="Download pamphlet"
                            />
                        </Col>
                        {/* <Col md={6} xl={3} className='col'>
                            <CardDocument
                                ClassName='bg-gray'
                                imgAlt='Tips for parents or caregivers of children living with narcolepsy thumbnail'
                                imgRef={tipsThumbnail}
                                cardDesc='Read some tips for parents <br> or caregivers of <br> children living <br>with narcolepsy.'
                                cardUrl='#'
                                btnText='Download tips'
                            />
                        </Col> */}
                        <Col md={6} lg={4} className="col">
                            <CardDocument
                                ClassName="bg-green"
                                imgAlt="Now approved for pediatric narcolepsy flashcard thumbnail"
                                imgRef={pediatricFlashcard}
                                imgClass="hasBoxShadow"
                                cardDesc="Download this overview about WAKIX."
                                cardUrl="/pdf/New-FDA-Approval-for-WAKIX-Information-for-Caregivers.pdf"
                                btnText="Download flashcard"
                            />
                        </Col>
                        <Col md={6} lg={4} className="col">
                            <CardDocument
                                ClassName="bg-gray"
                                imgAlt="Computer icon"
                                imgRef={signUpIcon}
                                imgClass="max-w-180"
                                cardDesc="Sign up to learn more about WAKIX."
                                cardUrl="https://wakix.com/sign-up"
                                btnText="Sign up here"
                            />
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="info-block bg-green block-copay-program">
                <div className="centered-content">
                    <div className="block-copay-program__wrap">
                        <div className="block-copay-program__content">
                            <div className="block-copay-program__media">
                                <img
                                    src={copayIcon}
                                    alt="$0 WAKIX copay program icon"
                                    className="copay-img-icon"
                                />
                            </div>
                            <div className="block-copay-program__title">
                                <h2>Copay program for eligible patients*</h2>
                                <div className="btn-wrap">
                                    <a
                                        rel="noopener noreferrer"
                                        className="btn btn-primary"
                                        href="https://wakix.com/wakix-for-you-support-program"
                                    >
                                        Get started <TfiAngleRight />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="block-copay-program__footnote">
                            <p className="footnote font-14">
                                *This offer is valid only for patients who have
                                commercial (nongovernment-funded) insurance.
                                Additional terms and conditions apply.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <SocialIconBlock />
        </HcpLayout>
    );
};

export default PediatricLandingPage;

export const Head = () => (
    <Seo
        title="WAKIX® (pitolisant) | Now Approved in People 6 Years of Age and Older"
        description="WAKIX is NOW FDA APPROVED to treat excessive daytime sleepiness (EDS) in children 6 years of age and older with narcolepsy. Learn about WAKIX."
        keywords="narcolepsy treatment children, pediatric narcolepsy treatment, narcolepsy medication children, pediatric narcolepsy medication, narcolepsy drugs children, pediatric narcolepsy drugs, pitolisant, excessive daytime sleepiness treatment children, WAKIX children, WAKIX pediatric patients, pitolisant children, pitolisant pediatric patients, WAKIX now FDA approved, WAKIX resources caregivers, WAKIX resources parents, WAKIX information caregivers, WAKIX information parents, pitolisant resources caregivers, pitolisant resources parents, pitolisant information caregivers, pitolisant information parents, narcolepsy resources caregivers, narcolepsy resources parents, narcolepsy education caregivers, narcolepsy education parents, new FDA approval for WAKIX"
    />
);
