import React from "react";
import { TfiAngleRight } from "react-icons/tfi";
import "./card-document.scss";

function CardDocument({
    ClassName,
    imgRef,
    imgAlt,
    imgClass,
    cardDesc,
    cardUrl,
    btnText,
    ...props
}) {
    return (
        <article {...props} className={`card-document ${ClassName ? ClassName : ""}`}>
            {imgRef ? (
                <div className={`card-document__media ${imgClass ? imgClass : ""}`}>
                    <img src={imgRef} alt={imgAlt} />
                </div>
            ) : ""}

            {cardDesc ? (
                <div className="card-document__desc">
                    <h4 dangerouslySetInnerHTML={{ __html: cardDesc }}></h4>
                </div>
            ) : ""}

            {cardUrl ? (
                <div className="card-document__btn">
                    <a
                        href={cardUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-primary"
                    >
                        {btnText}
                        <TfiAngleRight />
                    </a>
                </div>
            ) : " "}
        </article >
    );
}


export default CardDocument;