import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { HiMail } from "react-icons/hi";
// import { Link } from "gatsby";
import { TfiAngleRight } from "react-icons/tfi";
import "./social-icon-block.scss"

function SocialIconBlock({ isShareNeeded, removePadding }) {
  return (
    <div className="social-media-block">
      <div className={"centered-content social-cta cta-block " + (isShareNeeded ? " last " : "last-right ") + (removePadding ? "ccon-remove-pad" : "")}>
        {/* Conditionally render the cta-icon div based on the isShareNeeded value */}
        {isShareNeeded && (
          <div className="cta-icon">
            <p className="asap font-25 strong italic">
              Share this page:&nbsp;&nbsp;
            </p>
            <ul className="icon-group">
              <li>
                <FaFacebookF alt="Share via Facebook icon" />
              </li>
              <li>
                <BsTwitter alt="Share via Twitter icon" />
              </li>
              <li>
                <HiMail alt="Share via Email icon" />
              </li>
            </ul>
          </div>
        )}
        <div className="btn-wrap">
          <a
            href="https://wakix.com/sign-up"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-link asap font-25 strong normal blue-hover"
          >
            Stay informed about WAKIX&nbsp;
            <TfiAngleRight />
          </a>
        </div>
      </div>
    </div>
  );
}

export default SocialIconBlock;
